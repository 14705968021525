import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['template', 'follow', 'sender', 'form', 'result', 'action'];

  connect() {
    this.saveCount = 0;
    this.nextURL = null;
    this.savedForm = this.savedForm.bind(this);

    if (this.hasSenderTarget) {
      this.changeSender({ target: this.senderTarget });
    }
    this.initUploadModal();
    this.initModal();
  }

  resultTargetConnected(element) {
    if (element.dataset.redirectUrl) {
      Turbo.visit(element.dataset.redirectUrl)
      element.remove();
    }
  }

  actionTargetConnected(element) {
    if (element.dataset.url) {
      element.closest('form').action = element.dataset.url;
    }
  }

  followTargetConnected(element) {
  }

  followTargetDisconnected(element) {
  }

  senderTargetConnected(element) {
    element.addEventListener('change', this.changeSender.bind(this));
  }

  senderTargetDisconnected(element) {
    element.removeEventListener('change', this.changeSender.bind(this));
  }

  templateTargetConnected(select) {
    select.addEventListener('change', this.changeTemplate.bind(this));
  }

  templateTargetDisconnected(select) {
    select.removeEventListener('change', this.changeTemplate.bind(this));
  }

  changeSender(event) {
    const option = event.target.options[event.target.selectedIndex || 0];
    if (option) {
      Array.from(document.getElementsByClassName(`sender-value`)).forEach(element => {
        element.value = option.getAttribute('email');
      });
    }
  }

  changeTemplate(event) {
    const $section = event.target.closest('form');

    fetch(`/campaigns/templates/${event.target.value}.json`)
      .then(response => response.json())
      .then(data => {
        if ($section) {
          $section.querySelector('.subject').value = data.subject;
          $section.querySelector('.content').value = data.content;
        }
      });
  }

  removeFollowUp(event) {
    const id = event.target.dataset.id
    if (id) {
      event.target.closest(`#campaign_${id}`).remove()
    } else {
      event.target.closest(`form`).remove()
    }
  }

  initUploadModal() {
    // set the modal menu element
    const modalId = `upload-modal`;
    const $targetEl = document.getElementById(modalId);

    // options with default values
    const options = {
      placement: 'bottom-right',
      backdrop: 'dynamic',
      backdropClasses:
        'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
      closable: true,
      onHide: () => {
      },
      onShow: () => {
      },
      onToggle: () => {
      },
    };

    // instance options object
    const instanceOptions = {
      id: modalId,
      override: true
    };

    this.uploadModal = new Modal($targetEl, options, instanceOptions);
  }

  openUploadModal() {
    this.uploadModal.show()
  }

  closeUploadModal() {
    this.uploadModal.hide()
  }

  initModal() {
    // set the modal menu element
    const modalId = `crub-modal`;
    const $targetEl = document.getElementById(modalId);

    // options with default values
    const options = {
      placement: 'bottom-right',
      backdrop: 'dynamic',
      backdropClasses:
        'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
      closable: true,
      onHide: () => {
      },
      onShow: () => {
      },
      onToggle: () => {
      },
    };

    // instance options object
    const instanceOptions = {
      id: modalId,
      override: true
    };

    this.modal = new Modal($targetEl, options, instanceOptions);
  }

  openModal() {
    this.modal.show()
  }

  closeModal() {
    this.modal.hide()
  }

  next(event) {
    this.saveCount = 0;
    this.nextURL = event.target.dataset.url;
    const counter = this.formTargets.length || 0;

    this.toggleSubmitButton(event.target);

    if (!this.hasFormTarget || counter === 0) {
      console.warn("No forms found to submit.");
      Turbo.visit(event.target.dataset.url);
      return;
    }

    this.formTargets.forEach((form, index) => {
      setTimeout(() => {
        if (form.checkValidity()) {
          form.requestSubmit();
        } else {
          form.reportValidity();
          this.toggleSubmitButton(event.target);
        }
      }, index * 300); // Add a slight delay between submissions
    });
  }

  savedForm(event) {
    if (this.nextURL) {
      this.saveCount = this.saveCount + 1;
      if (this.saveCount === this.formTargets.length) {
        Turbo.visit(this.nextURL);
      }
    }
  }

  lauched() {
    Turbo.visit('/campaigns')
  }

  toggleSubmitButton(button) {
    try {
      const originalContent = button.innerHTML;
      const loadingContent = button.getAttribute('data-turbo-submits-with');

      button.setAttribute('data-turbo-submits-with', originalContent);
      button.innerHTML = loadingContent;
      button.disabled = !button.disabled;
    } catch (error) {
      console.error("Error in toggleSubmitButton method:", error);
    }
  }
}
